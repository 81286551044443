<template>
    <div>
		<b-modal ref="modalPrintInfosHorse" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("horse.print_infos_horse") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
				<label for="horses" class="col-form-label">{{ $t("horse.format") }} *</label>
				<e-select
						v-model="format"
						id="format"
						label="name"
						:placeholder="labelTitleFormat"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="formats"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<label for="languages" class="col-form-label">{{ $t("global.language") }}</label>
					<e-select
						v-model="localeSelected"
						id="languages"
						label="title"
						track-by="code"
						:options="locales"
						:allow-empty="false"
					/>
				</div>

				<div class="form-group">
					<label for="skip" class="col-form-label">{{ $t("horse.ignorer_vignettes") }} *</label>
					<input type="number" class="form-control" v-model="skip">
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Common from '@/assets/js/common.js'
import Horse from '@/mixins/Horse.js'
import Model from "@/mixins/Model.js"
import Vue from 'vue'

export default {
	name: "HorseListe",
	mixins: [Horse, Model],
	data () {
		return {
			formats: [],
			templates: [],
			format: null,
			template: null,
			skip: 0,
			horse_ids: null,
			processing: false,
			localeSelected: null,
			locales: this.getLocales(),
			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.localeSelected = this.locales.find(lang => lang.code == (Vue.i18n.locale()))
			this.template = null
			this.skip = 0

			this.formats = this.getAllFormats()
			this.format = this.formats.find(f => f.name == 'A4')
			this.templates = await this.loadAllModel(0, ['horse_envoie_infos'])
		},
		openModal(horse_ids) {
			this.horse_ids = horse_ids
			this.$refs.modalPrintInfosHorse.show()
		},
		async checkForm() {
			if(this.template && this.format && this.format.id != 6) {
				this.processing = true
				this.print_infos_horse(this.horse_ids, this.format.id, this.template.model_id, parseInt(this.skip))
				.then(() => {
					this.processing = false
				})
			}
			else if(this.format && this.template) {
				this.processing = true

				let model_ids = []
				let model_label = ""

				if(typeof(this.horse_ids) === 'string') {
					this.horse_ids = this.horse_ids.split(',')
				}

				await Common.asyncForEach(this.horse_ids, async (horse_id) => {
					const model = await this.createModel(horse_id, this.template.type.modeltype_id, this.template.model_label, this.template.type.modeltype_template)
					await this.cloneModel(this.template.model_id, model.model_id)
					await this.generateCourrier(model.model_id, this.template.type.modeltype_template, horse_id, null, null, this.localeSelected.code)
					model_ids.push(model.model_id)
					model_label = this.template.model_label
				})

				await this.printCourrier(model_ids, model_label, false, this.localeSelected.code)
				this.processing = false
				this.$refs.modalPrintInfosHorse.hide()
			}
			else {
				this.failureToast()
			}
		}
	}
}
</script>